import { Layout } from '@cfra-nextgen-frontend/shared';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { TypographyStyle2 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, createTheme, Stack, SxProps, ThemeProvider, GridSize, Theme } from '@mui/material';

export const noResultText = 'No results found';

const bannerContainerStyles = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
};

const bannerHeaderStyles = {
    height: '60px',
    padding: '20px',
    alignItems: 'center',
};

export const childContainerStyles = {
    margin: '20px 8px 20px 20px',
    paddingRight: '12px',
    maxHeight: '391px',
    overflowY: 'auto',
    ...scrollbarThemeV3,
};

export interface BannerCardProps {
    title: string | React.ReactNode;
    bannerImage: any;
    isLoading?: boolean | undefined;
    noResults?: boolean | undefined;
    subHeader?: React.ReactNode;
    childrenContainerSx?: SxProps;
    children: React.ReactNode;
    headerComponents?: Array<React.ReactNode>;
    containerStyles?: SxProps;
    headerConfiguration?: {
        boxSx?: SxProps;
        containerSx?: SxProps;
        titleSx?: SxProps;
        titleXs?: GridSize | boolean;
        headerComponentsXs?: GridSize | boolean;
    };
    customTheme?: Theme;
    loadingIndicatorSx?: SxProps;
    outerNoResultText?: string;
}

export function BannerCard(props: BannerCardProps) {
    const { title, bannerImage, subHeader, isLoading = true, noResults = true, containerStyles = {}, customTheme = {} } = props;

    const childrenContainerSx = props.childrenContainerSx ? props.childrenContainerSx : childContainerStyles;

    const currentTheme = createTheme();

    return (
        <ThemeProvider theme={createTheme(currentTheme, customTheme)}>
            <Stack sx={{ ...bannerContainerStyles, ...containerStyles }}>
                <Box sx={{ ...bannerHeaderStyles, ...props.headerConfiguration?.boxSx }}>
                    <Grid container sx={props.headerConfiguration?.containerSx}>
                        <Grid item xs={props.headerConfiguration?.titleXs ?? 10}>
                            {typeof title === 'string' ? (
                                <TypographyStyle2
                                    fontWeight={fontWeights.Medium}
                                    sx={props.headerConfiguration?.titleSx}>
                                    {title}
                                </TypographyStyle2>
                            ) : (
                                title
                            )}
                        </Grid>
                        <Grid item xs={props.headerConfiguration?.headerComponentsXs ?? 2}>
                            <Stack direction='row' sx={{ justifyContent: 'right' }}>
                                {props.headerComponents && props.headerComponents.length > 0 && props.headerComponents}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box component='img' src={bannerImage} width='100%' height='auto' />
                {subHeader}
                <Box sx={childrenContainerSx}>
                    {isLoading && (
                        <Box sx={{ margin: '48px 0', width: '100%', textAlign: 'center', ...props.loadingIndicatorSx }}>
                            <Layout.Skeleton height='10px' />
                        </Box>
                    )}
                    {!isLoading && noResults && (
                        <Box sx={{ margin: '48px 0', width: '100%', textAlign: 'center' }}>
                            <ResearchDescriptionText>{props.outerNoResultText || noResultText}</ResearchDescriptionText>
                        </Box>
                    )}
                    {props.children}
                </Box>
            </Stack>
        </ThemeProvider>
    );
}
