export function getDataFromLocalStorage(key: string) {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
}

export function setDataToLocalStorage(key: string, value: Object) {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeDataFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
}

export function getDataFromSessionStorage(key: string) {
    const item = window.sessionStorage.getItem(key);
    if (!item && typeof item !== 'string') return undefined;
    try {
        return JSON.parse(item);
    } catch {
        return item;
    }
}

export function setDataToSessionStorage(key: string, value: any) {
    const stringValue = typeof value === 'string' 
        ? value
        : JSON.stringify(value);
    window.sessionStorage.setItem(key, stringValue);
}

export function removeDataFromSessionStorage(key: string) {
    window.sessionStorage.removeItem(key);
}
